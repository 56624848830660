:root {
  --backgroundColor: rgb(0, 0, 0);
  --colorShadeA: rgb(163, 128, 106);
  --colorShadeB: rgb(186, 133, 121);
  --colorShadeC: rgb(232, 186, 150);
  --colorShadeD: rgb(232, 207, 187);
  --colorShadeE: rgb(236, 95, 14);
}


/* vt323-regular - latin */
@font-face {
  font-family: 'VT323';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/vt323-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/vt323-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/vt323-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/vt323-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/vt323-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/vt323-v17-latin-regular.svg#VT323') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/open-sans-v34-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/open-sans-v34-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/open-sans-v34-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/open-sans-v34-latin-300.woff') format('woff'), /* Modern Browsers */
         url('./fonts/open-sans-v34-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/open-sans-v34-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-regular - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./fonts/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-500 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/open-sans-v34-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/open-sans-v34-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/open-sans-v34-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/open-sans-v34-latin-500.woff') format('woff'), /* Modern Browsers */
         url('./fonts/open-sans-v34-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/open-sans-v34-latin-500.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
.main{
  overflow: hidden;
  background-color: black;
}



  .image {
     min-height: 100vh;
     position: relative;
   }
   
   .image .content {
     position: absolute;
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     height: 100vh;
   }

  .Moon {
     height: auto;
     width: 50%;
     position: relative;

   }
   .LogoMain{
    position:relative;
    width: 40%;
    top: 254px;
   }
   
   .NFTs{
    height: 300px;
   }
    
    .content span.img-txt {
      background-color: rgb(0, 0, 0);
      text-transform: uppercase;
      color: #fff;
      padding: 1rem;
      font-size: 1.5rem;
      letter-spacing: 10px;
    }
    
    .h3 {
      color: white;
      letter-spacing: 6px;
      text-transform: uppercase;
      font-size: 100px;
      text-align: center;
    }
    
    .text-box {
      text-align: center;
      padding: 3rem 1rem;
      text-align: justify;
    }
    .team{
      animation: fadeIn 10s,;

    }
    .NFTc{
      min-height: 100vh;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

    }
    .NFTContainer{
      width: 85%;
      margin: 1rem auto;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
    }
    .NFTitem{
      width: calc(20rem - 4vw);
      padding: 1rem 0;
      margin: 2rem 0.1rem;
      position: relative;
      
      z-index:5;
    }
  
.swiper-slide{
  border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;}

    .roadmap{
      justify-content: center;
      align-items: center;
      align-self:center;
      align-content: center;
      min-height: 100vh;
      width: 100%;
      background-size: cover;
      position: absolute;
      padding-top:10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      animation: fadeIn2 2.2s ease-in-out;
    }
    .roadmap2{
      justify-content: center;
      align-items: center;
      align-self:center;
      align-content: center;
      min-height: 100vh;
      width: 100%;
      background-size: cover;
      position: absolute;
      padding-top:10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
    }
    
    .logoRoadmap {
      padding-top: 2%;
      position: relative;
      animation: glitch 1.9s 5s infinite;
      }
    
    .logoTeam {
      padding-top: 2%;
      animation: glitch 1.9s 5s infinite;
      }
    
    .logoFAQ{
      padding-top: 2%;
      animation: glitch 3.9s 5s infinite;
      }
    .Faq{
      animation: bounceFaq 9s 2s infinite;
    }
    .Team{
      animation: bounceTeam 8s 2s infinite;
    }
    .swiper{
      width: 100%;
      height: 90%;
      animation: bounce 3.9s infinite, glitch 9s 1s infinite;
        display: flex;
        justify-content: center;
        align-items: center;

  }   


  .swiperimg{
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .butC{
    top:192px ;
    right: 4%;
    position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      z-index: 222;
    }
    
    .ImageRoadmap{
      position: relative;
      width: 120px;
      height: 120px;
      object-fit: contain;
      /* animation: bounce 1.9s infinite; */
    }
    .ImageRoadmap:hover {
      
            border:rgb(10, 210, 250) solid;
            border-radius: 222px;
      animation:  glitch 12s 6s infinite;

    }
    .buttong{
      font-family: 'VT323', 'arial';
      background-color: #fb962b;
      color: #000000;
      font-size: 24px;
      padding: 10px;
      width: 122px;
      border-radius: 32px;
      border: 2px rgb(250, 213, 182) solid;
      text-shadow:
      0 0 7px rgba(255, 255, 255, 0.452),
      0 0 10px rgba(255, 255, 255, 0.528),
      0 0 21px rgba(255, 255, 255, 0.352),
      0 0 42px rgb(255, 48, 151),
      0 0 82px rgba(255, 0, 242, 0.234),
      0 0 92px rgba(0, 255, 170, 0.1),
      0 0 102px rgb(183, 27, 223),
      0 0 151px rgb(255, 0, 43);
    }
    
    .buttong:active {
      box-shadow: 5px 5px #2353E0, -5px 5px #2353E0, 0px 10px #2353E0, 0px 0px #133DB9, 0px 15px #2979FF, -5px 10px #2A6CDA, 5px 10px #2A6CDA;
      background: #2353E0;
      padding-top: 15px;
      padding-bottom: 5px;
      transition: box-shadow 0.1s, background 0.1s, padding 0.1s;
      color: #eee;
    }
    .buttong:hover {
      box-shadow: 5px 5px #2353E0, -5px 5px #2353E0, 0px 10px #2353E0, 0px 0px #133DB9, 0px 15px #2979FF, -5px 10px #2A6CDA, 5px 10px #2A6CDA;
      background: #2353E0;
      transition: box-shadow 0.1s, background 0.1s, padding 0.1s;
      color: #eee;
      text-shadow:
      0 0 7px rgba(255, 255, 255, 0.229),
      0 0 10px rgba(255, 255, 255, 0.255),
      0 0 21px rgba(255, 255, 255, 0.352),
      0 0 42px rgb(255, 48, 151),
      0 0 82px rgba(255, 0, 242, 0.234),
      0 0 92px rgba(0, 255, 170, 0.1),
    }

    

    .City{
      width: 100%;
      height:500px;
      padding-top: 124px;
    }

    .buttonMain {
      font-family: 'VT323', 'arial';
      background: none;
      border: none;
      outline: none;
      display: block;
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      padding: 10px;
      box-shadow: 5px 0px #133DB9, -5px 0px #133DB9, 0px 5px #133DB9, 0px -5px #133DB9, 0px 15px #2979FF, -5px 10px #2A6CDA, 5px 10px #2A6CDA;
      background: #133DB9;
      color: #eee;
      transition: box-shadow 0.1s, background 0.1s, padding 0.1s;
    }
    
    button:active {
      box-shadow: 5px 5px #2353E0, -5px 5px #2353E0, 0px 10px #2353E0, 0px 0px #133DB9, 0px 15px #2979FF, -5px 10px #2A6CDA, 5px 10px #2A6CDA;
      background: #2353E0;
      padding-top: 15px;
      padding-bottom: 5px;
      transition: box-shadow 0.1s, background 0.1s, padding 0.1s;
    }
    @keyframes fadeIn2 {
      from {
        opacity: 0;
   
      }
      to {
        opacity: 1;

      }
    }
    @keyframes flare {
      100% {
        transform: translateY(-20px) scale(1.5);
        filter: blur(10px);
        opacity: 0;
      }
    }
    
    @keyframes burn {
      0% {
        color: rgba(255, 130, 110, 1);
        background: rgba(255, 230, 110, 1);
        box-shadow:
          0 0 5px 0 rgba(200, 0, 10, 1),
          0 0 5px 0 rgba(230, 30, 10, 0.8),
          0 0 5px 0 rgba(230, 230, 10, 0.6);
      }
      
      100% {
        color: rgba(0, 0, 0, 1);
        background: rgba(255, 255, 255, 1);
        box-shadow:
          0 -35px 40px 30px rgba(255, 130, 10, 0),
          0 -30px 30px 10px rgba(230, 30, 10, 0),
          0 -20px 10px 0 rgba(255, 255, 10, 0);
      }
    }
    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
    
    
    @keyframes glitch {
      1%{transform: rotateX(10deg) skewX(90deg); }
      2%{transform: rotateX(0deg) skewX(0deg);  }
    }
    
    @keyframes bounce {
        10%, 110%, 150%, 180%, 100% {transform: translateY(1);}
        40% {transform: translateY(-20px);}
        60% {transform: translateY(-15px);}
  }
    @keyframes bounceTeam {
        10%, 110%, 150%, 180%, 100% {transform: translateY(1);}
        40% {transform: translateY(-20px);}
        60% {transform: translateX(-10px);}
        60% {transform: translateY(15px);}
        60% {transform: translateX(10px);}
  }
    @keyframes bounceFaq {
        10%, 110%, 150%, 180%, 100% {transform: translateY(1);}
        40% {transform: translateY(-10px);}
        60% {transform: translateY(-1px);}
  }

  @media (max-width: 900px) {
    .LogoMain {
      width: 300px;  
    }
    .City{
      width: 100%;
      height: auto;
      padding-top: 36%;
    }
  }
  @media (max-width: 600px) {
    .swiper{
      height: 70%;

    }
    .buttong{
      font-size: 14px;
      padding: 10px;
      width: auto;
      height: 44px;
      margin-left: 12%;
    }
    .ImageRoadmap{
      top:122px ;
      left: 18%;
      width: auto;
      height: 42px;
      margin-left: 1%;
      animation: null;
    }
    .butC{
      top:-32px ;
      right: 25%;
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: center;
      z-index: 222;
    }
    .logoRoadmap {
      width: 150px;    
      padding-top: 10%;  
    }
    .logoFAQ {
      width: 100px;    
    }
    .logoTeam {
      padding-top: 10%;  
      width: 100px;      
    }
    .LogoMain {
      width: 300px;  
    }
    .Moon {
      width: 70%; 
      padding-top: 16% 
    }
    .roadmap{
       min-height: auto;
    background-position: 43%;
    background-size: cover;
    
    }
    .roadmap2{
       min-height: auto;
    background-position: 43%;
    background-size: cover;
    }
    .City{
      width: 100%;
      height: auto;
      padding-top: 126%;
    }
  }
  



